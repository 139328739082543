import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from "react-leaflet";
import MarkerJS from "./Marker.js";

const center = window.location.origin.includes("kk")
  ? [16.43192755724492, 102.82856609781523]
  : window.location.origin.includes("mdh")
  ? [16.59738946623057, 104.51104253757008]
  : window.location.origin.includes("spb")
  ? [14.651579065119135, 99.93989999520554]
  : [16.43192755724492, 102.82856609781523];

function MapChart({ data, subdistrictObj }) {
  const [map, setMap] = useState(null);
  const [showGeoJSON, setShowGeoJSON] = useState(true);
  const [showGeoJSON2, setShowGeoJSON2] = useState(true);
  const [showMarker, setShowMarker] = useState(false);
  const timer = useRef();
  const [showBaby, setShowBaby] = useState(true);
  const [showKid, setShowKid] = useState(true);
  const [showTeens, setShowTeens] = useState(true);
  const [showAdult, setShowAdult] = useState(true);
  const [showMenopause, setShowMenopause] = useState(true);
  const [showOld, setShowOld] = useState(true);
  const [showMale, setShowMale] = useState(true);
  const [showFemale, setShowFemale] = useState(true);

  const popupFeature = (e) => {
    let layer = e.target;
    const { count, tam_th } = e.target.feature.properties;

    if (count > 0) {
      const popupOptions = {
        minWidth: 100,
        maxWidth: 250,
        className: "popup-classname",
      };

      layer
        .bindTooltip(() => {
          return `<b>${tam_th} : จำนวนผู้ป่วย ${count}</b>`;
        }, popupOptions)
        .openTooltip();
    }

    const originalStyle = {
      weight: layer.options.weight,
      color: layer.options.color,
      dashArray: layer.options.dashArray,
      fillOpacity: layer.options.fillOpacity,
    };

    layer.options.originalStyle = originalStyle;

    layer.setStyle({
      weight: 3,
      color: "#f00",
      dashArray: "",
      fillOpacity: 0.7,
    });
    layer.bringToFront();
  };

  const popupFeatureOut = (e) => {
    let layer = e.target;
    const originalStyle = layer.options.originalStyle;

    if (originalStyle) {
      layer.setStyle(originalStyle);
    }
    layer.bringToBack();
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: popupFeature,
      mouseout: popupFeatureOut,
    });
  };

  const mapPolygonColorToDensity = (d) => {
    if (d > 10000) return "#800026";
    if (d > 5000) return "#BD0026";
    if (d > 2000) return "#E31A1C";
    if (d > 1000) return "#FC4E2A";
    if (d > 500) return "#FD8D3C";
    if (d > 200) return "#FEB24C";
    if (d > 100) return "#FED976";
    if (d >= 1) return "#FFEDA0";
    return "#000";
  };

  const style = (feature) => {
    return {
      fillColor: mapPolygonColorToDensity(feature.properties.count),
      weight: 1,
      opacity: 1,
      color: "white",
      dashArray: "2",
      fillOpacity: 0.5,
      transition: "5s ease-in-out",
      className: "marker marker-fade-in",
    };
  };

  const filteredMarkers = data.filter((marker) => {
    const age = parseInt(marker.age);
    const isMale = marker.sex === "ชาย";
    const isFemale = marker.sex === "หญิง";

    if ((isMale && !showMale) || (isFemale && !showFemale)) return false;

      if (age >= 0 && age <= 1 && showBaby) return true;
      if (age > 1 && age <= 12 && showKid) return true;
      if (age >= 13 && age <= 20 && showTeens) return true;
      if (age >= 21 && age <= 40 && showAdult) return true;
      if (age >= 41 && age < 60 && showMenopause) return true;
      if (age >= 60 && showOld) return true;

      if (age >= 0 && age <= 1 && showBaby) return true;
      if (age > 1 && age <= 12 && showKid) return true;
      if (age >= 13 && age <= 20 && showTeens) return true;
      if (age >= 21 && age <= 40 && showAdult) return true;
      if (age >= 41 && age < 60 && showMenopause) return true;
      if (age >= 60 && showOld) return true;

    return false;
  });

  useEffect(() => {
    if (map) {
      map.on("zoomend", () => {
        const currentZoom = map.getZoom();
        if (currentZoom < 12) {
          setShowGeoJSON(currentZoom < 12);
          setShowMarker(currentZoom >= 12);
          timer.current = setTimeout(() => {
            setShowGeoJSON2(true);
          }, 300);
        } else {
          setShowGeoJSON2(currentZoom < 12);
          setTimeout(() => {
            setShowGeoJSON(false);
            setShowMarker(true);
          }, 300);
        }
      });
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [map]);
  return (
    <>
      <Grid container columns={12}>
        <Box className="box-info" sx={{ background: "#333333" }} width="100%">
          <Stack spacing={1} className="flex-center" width="100%">
            <Typography style={{ margin: 5 }}>แผนที่แสดงจำนวนคนผู่ป่วยเป็นรายบ้านแบบดาวเทียม</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} className={showGeoJSON2 ? "n" : ""}>
          <MapContainer
            scrollWheelZoom={true}
            center={center}
            zoom={9}
            className="mapContainer"
            ref={setMap}
            // fadeAnimation={true}
            // markerZoomAnimation={true}
            // zoomAnimation={true}
            // zoomAnimationThreshold={100}
            // zoomSnap={0.25}
            // zoomDelta={0.25}
          >
            <TileLayer
              // ตรวจสอบสถานะ satelliteView
              maxZoom={18}
              url={
                "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              }
              attribution={
                "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              }
            />
            {showGeoJSON && (
              <GeoJSON
                data={subdistrictObj}
                style={(e) => ({
                  ...style(e),
                })}
                onEachFeature={onEachFeature}
              />
            )}

            {showMarker && <MarkerJS filteredMarkers={filteredMarkers} />}
            {/* <MarkerJS filteredMarkers={filteredMarkers} /> */}
          </MapContainer>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography>
              ทารก
              <Checkbox checked={showBaby} onChange={(e) => setShowBaby(e.target.checked)} color="success" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | เด็ก
              <Checkbox checked={showKid} onChange={(e) => setShowKid(e.target.checked)} color="success" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | วัยรุ่น
              <Checkbox
                checked={showTeens}
                onChange={(e) => setShowTeens(e.target.checked)}
                color="success"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | ผู้ใหญ่
              <Checkbox
                checked={showAdult}
                onChange={(e) => setShowAdult(e.target.checked)}
                color="success"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | วัยทอง
              <Checkbox
                checked={showMenopause}
                onChange={(e) => setShowMenopause(e.target.checked)}
                color="success"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | ผู้สูงอายุ
              <Checkbox checked={showOld} onChange={(e) => setShowOld(e.target.checked)} color="success" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | เพศชาย
              <Checkbox checked={showMale} onChange={(e) => setShowMale(e.target.checked)} color="success" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              | เพศหญิง
              <Checkbox
                checked={showFemale}
                onChange={(e) => setShowFemale(e.target.checked)}
                color="success"
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default MapChart;
