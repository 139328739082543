import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad.js";
import { GETPERSON, POST } from "../../service.js";
import { headerTable } from "../../untils/static.js";
import PersonCard from "./Person/PersonCard.js";

function Person() {
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [sendLineNotice, setSendLineNotice] = useState(true);
    const [idNumber, setIdNumber] = useState("");
    const [text, setText] = useState("");
    const [data, setData] = useState([]);
    const [lengthData, setLengthData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = async (event, newPage, sendLineNotice) => {
        // console.log(newPage);
        setPage(newPage);
        setSendLineNotice(false);
        // getPerson(rowsPerPage, newPage, sendLineNotice);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        // getPerson(event.target.value, page);
    };

    const getPerson = async () => {
        try {
            setLoading(true);
            let res = await POST(GETPERSON, { rowperpage: rowsPerPage, page: page + 1, sendLineNotice });
            if (res.success) {
                setData(res.result.data);
                setLengthData(res.result.lengthData);
                setSendLineNotice(false);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const handleSearch = async () => {
        if (idNumber && idNumber.length !== 13) {
            Swal.fire({
                icon: "warning",
                text: `กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก`,
                confirmButtonText: "ตกลง",
            });
        } else {
            try {
                setLoadingBtn(true);
                let res = await POST(GETPERSON, { text, person_id: idNumber, rowperpage: rowsPerPage, page: 1, sendLineNotice });
                if (res.success) {
                    setPage(0);
                    setData(res.result.data);
                    setLengthData(res.result.lengthData);
                    setLoadingBtn(false);
                } else {
                    setLoadingBtn(false);
                    Swal.fire({
                        icon: "warning",
                        text: `${res.message}`,
                        confirmButtonText: "ตกลง",
                    });
                }
            } catch (error) {
                setLoadingBtn(false);
                Swal.fire({
                    icon: "warning",
                    text: `${error}`,
                    confirmButtonText: "ตกลง",
                });
            }
        }
    };

    const handleReset = async () => {
        try {
            setLoadingBtn(true);
            let res = await POST(GETPERSON, { text: "", person_id: "", rowperpage: rowsPerPage, page: page + 1, sendLineNotice: false });
            if (res.success) {
                setPage(0);
                setData(res.result.data);
                setLengthData(res.result.lengthData);
                setLoadingBtn(false);
            } else {
                setLoadingBtn(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoadingBtn(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            });
        }
    };

    // useEffect(() => {
    //     getPerson();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sendLineNotice, rowsPerPage, page]);

    return (
        <>
            <BackdropLoad loading={loading} />
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <PersonCard
                    person_id={idNumber}
                    text={text}
                    setIdNumber={setIdNumber}
                    setText={setText}
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loadingBtn={loadingBtn}
                    data={data}
                    header={headerTable.person_header}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    lengthData={lengthData}
                />
            </Container>
        </>
    );
}

export default Person;
