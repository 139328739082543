import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Paper,
    Typography,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    TextField,
    Stack,
    Container,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Autocomplete,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from "@mui/material";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
    ManageAccountsDrawerIcon,
} from '../../../untils/icons';

import {
    ButtonTeal,
} from '../../../Theme';

import dayjs from 'dayjs';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getToken } from '../../../untils/shortcut';

function GridTextField({
    action,
    dataScreen,
    dataRole,
    dataLocation,
    handleChangeText,
    handleAddUser,
    handleUpdateUser,
    handleChangeCheckbox,
    errorIdCard,
    errorPhone,
    errorEmail,
    birthDate,
    roleId,
    // location,
    name,
    lastname,
    id_card,
    phone,
    email,
    setName,
    setLastname,
    setRoleId,
    setLocation,
    setIdCard,
    setPhone,
    setEmail,
    setBirthDate,
    setPassword,
    setConfirmPassword,
    checkAll,
    setSearchLoca,
    searchLoca,
    setInputLoca,
}) {

    const [cleared, setCleared] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordFirm, setShowPasswordFirm] = useState(false);
    const profile = getToken(localStorage.getItem("profile"));

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPasswordFirm = () => setShowPasswordFirm((show) => !show);

    const handleMouseDownPasswordFirm = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    return (
        <>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <ManageAccountsDrawerIcon fontSize='medium' />
                                <Typography className="main-header" >
                                    {action === "insert" ? "สร้างบัญชีผู้ใช้งาน" : "แก้ไขบัญชีผู้ใช้งาน"}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Box >
            <Container maxWidth="sm" >
                <Grid container columns={12} >
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ชื่อ :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="ชื่อ"
                                name="name"
                                placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>นามสกุล :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="นามสกุล"
                                name="lastname"
                                placeholder="กรุณากรอกนามสกุลผู้ใช้งาน"
                                variant="outlined"
                                value={lastname}
                                onChange={(e) => setLastname(e.currentTarget.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>เลขบัตรประจำตัวประชาชน :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="เลขบัตรประจำตัวประชาชน"
                                name="id_card"
                                placeholder="กรุณากรอกเลขบัตรประจำตัวประชาชน 13 หลัก"
                                variant="outlined"
                                value={id_card}
                                error={errorIdCard}
                                onChange={(e) => setIdCard(handleChangeText(e))}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="text"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start" >
                            <Typography>บทบาท :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} mb={2}>
                            <FormControl fullWidth>
                                <InputLabel >เลือกบทบาท</InputLabel>
                                <Select
                                    name="role"
                                    value={roleId}
                                    label="เลือกบทบาท"
                                    required
                                    onChange={(e) => setRoleId(handleChangeText(e))}
                                >
                                    {dataRole &&
                                        dataRole.filter((ele) => ele.role_id >= profile.role_id).map((el) => (
                                            <MenuItem key={el.role_id} value={el.role_id} disabled={el.disabled}>
                                                {el.role_name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>หมายเลขโทรศัพท์ :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="หมายเลขโทรศัพท์"
                                name="phone"
                                placeholder="กรุณากรอกหมายเลขโทรศัพท์มือถือ 10 หลัก"
                                variant="outlined"
                                value={phone}
                                error={errorPhone}
                                type="text"
                                onChange={(e) => setPhone(handleChangeText(e))}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ชื่อผู้ใช้งาน :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <TextField
                                required
                                fullWidth
                                label="ชื่อผู้ใช้งาน"
                                name="username"
                                placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                                variant="outlined"
                                value={email}
                                // error={errorEmail}
                                // onChange={(e) => setEmail(handleChangeText(e))}
                                onChange={(e) => setEmail(e.currentTarget.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>รหัสผ่าน :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    name="password"
                                    variant="outlined"
                                    onChange={(e) => setPassword(e.currentTarget.value)}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                            <Typography>ยืนยันรหัสผ่าน :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} my={2}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">ยืนยันรหัสผ่าน</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    name="confirm_password"
                                    variant="outlined"
                                    onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                                    type={showPasswordFirm ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordFirm}
                                                onMouseDown={handleMouseDownPasswordFirm}
                                                edge="end"
                                            >
                                                {showPasswordFirm ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {birthDate &&
                        <Grid item xs={12} className="flex-center">
                            <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start">
                                <Typography>วัน/เดือน/ปี เกิด :</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} my={2}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(birthDate)}>
                                        <DatePicker
                                            value={birthDate}
                                            slotProps={{
                                                field: { clearable: true, onClear: () => setCleared(true) },
                                            }}
                                            name="birthDate"
                                            label="วัน/เดือน/ปี เกิด"
                                            format='DD/MM/YYYY'
                                            onChange={(e) => setBirthDate(e)}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} className="flex-center">
                        <Grid item xs={4} my={2} display={{ xs: "none", sm: "block" }} className="flex-start" >
                            <Typography>รพ.สต. :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} mb={2}>
                            <Autocomplete
                                disabled={profile.role_id > 2 ? true : false}
                                fullWidth
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : `${option.location_code} ${option.location_name}`
                                }
                                filterOptions={(x) => x}
                                options={dataLocation}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                value={searchLoca}
                                noOptionsText="No locations"
                                onChange={(event, newValue) => {
                                    // console.log("newValue", newValue);
                                    setSearchLoca(newValue);
                                    if (!newValue) {
                                        return;
                                    }
                                    if (newValue) {
                                        setLocation(newValue.location_id);
                                    }
                                }}
                                onInputChange={(event, newInputValue) => {
                                    setInputLoca(newInputValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {(profile.role_id === 1 || profile.role_id === 2 || profile.role_id === 3) &&
                            <>
                                <Grid item xs={12} sm={4} md={4} className="flex-start" >
                                    <Typography>เลือกแถบแสดงเมนู :</Typography>
                                </Grid>
                                <Grid container columns={12} ml={5} my={2} spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} >
                                        <FormControlLabel
                                            label="ทั้งหมด"
                                            control={
                                                <Checkbox
                                                    checked={checkAll === -1 ? true : false}
                                                    name="ทั้งหมด"
                                                    onChange={(e) => handleChangeCheckbox(e)}
                                                />
                                            }
                                        />
                                    </Grid>
                                    {type_screen.map((element) =>
                                        <>
                                            <Grid item xs={12} mt={1} ml={"-5%"}>
                                                <Typography >●&nbsp;&nbsp;{element.label}</Typography>
                                            </Grid>
                                            {dataScreen.filter((e) => e.type === element.value).map((ele, index) =>
                                                <Grid item xs={12} sm={6} md={6} key={index + 1}>
                                                    <FormControl component="fieldset" variant="standard">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                key={index + 1}
                                                                control={
                                                                    <Checkbox onChange={(e) => handleChangeCheckbox(e)} checked={ele.check} value={ele.screen_id} name={ele.screen_name} />
                                                                }
                                                                label={ele.screen_name}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid item my={5} xs={12}>
                        {action === "insert" ?
                            <ButtonTeal size='large' fullWidth onClick={handleAddUser}>
                                บันทึกข้อมูล
                            </ButtonTeal> :
                            <ButtonTeal size='large' fullWidth onClick={handleUpdateUser}>
                                แก้ไขข้อมูล
                            </ButtonTeal>
                        }
                    </Grid>
                </Grid>
            </Container >
        </>
    )
}

export default GridTextField;

const type_screen = [
    { label: "หน้าหลัก", value: 0 },
    { label: "ระบบคลังยา", value: 1 },
    { label: "ระบบสนันสนุนการเคลม", value: 2 },
    { label: "ระบบ Dashboard", value: 3 },
    { label: "ฐานข้อมูลประชากร", value: 4 },
]


