import { Box, Button, Card, CardContent, Container, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import Swal from "sweetalert2";
import { GET, OTPMASTER, OTPVERIFY, POST, RESENDOTP } from "../../service";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { useNavigate } from "react-router-dom";


function Otp() {

    // const [data, setData] = useState([]);
    const [otp, setOtp] = useState('');
    const [ref, setRef] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const getOtp = async () => {
        try {
            setLoading(true);
            let res = await GET(OTPMASTER);
            // console.log('res',res);
            if (res.success) {
                setRef(res.result);
                console.log(res.result);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
        })
    }

}

const handleVerifyOtp = async () => {
    try {
        setLoading(true);
        let res = await POST(OTPVERIFY, { otp, ref });
        if (res.success) {
            setLoading(false);
            Swal.fire({
                icon: 'success',
                text: `ยืนยันข้อมูลสำเร็จ`,
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.isConfirmed) {
                    // window.location.reload();
                    navigate('/Login');
                };
            });
        } else {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${res.message}`,
                confirmButtonText: "ตกลง",
            });
        }
    } catch (error) {
        setLoading(false);
        Swal.fire({
            icon: "warning",
            text: `${error}`,
            confirmButtonText: "ตกลง",
        });
    }
}

const resendOtp = async () => {
    try {
        setLoading(true);
        let res = await GET(RESENDOTP);
        // console.log('res',res);
        if (res.success) {
            setRef(res.result);
            console.log(res.result);
            setLoading(false);
        } else {
            setLoading(false);
            Swal.fire({
                icon: "warning",
                text: `${res.message}`,
                confirmButtonText: "ตกลง",
            });
        }
    } catch (error) {
        setLoading(false);
        Swal.fire({
            icon: "warning",
            text: `${error}`,
            confirmButtonText: "ตกลง",
    })
}

}

useEffect(() => {
    getOtp();
}, []);

    return (
        <>
        <BackdropLoad loading={loading} />
        <Container maxWidth="sm" sx={{ position: "relative" }}>
            <Box className="flex-center" height="90vh">
            <Grid container columns={12} className="flex-center">
                        <Grid item xs={12} className="flex-center">
                            <Typography className="header" >OTP</Typography>
                        </Grid>
            <Grid item my={1} xs={12} className="flex-center">
                <Card sx={{ minWidth: 250 }} >
                    <Stack width="100%" className="flex-center" spacing={2}>
                        <CardContent sx={{ p: 5}}>
                        <Typography mb={3}>
                            <OTPInput
                            inputType="tel"
                            containerStyle={{display: 'unset'}}
                            inputStyle={{ width: "2.1rem", height: "2.1rem" }}
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span> - </span>}
                            renderInput={(props) => <input{...props}/>}
                            />
                        </Typography>
                            <Typography sx={{ fontSize: '14px' }} align='center'>
                                *Ref : {ref}
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }} align='center'>
                                OTP จะส่งเข้าสู่ระบบหมอพร้อม
                            </Typography>
                    </CardContent>
                    </Stack>
                </Card>
            </Grid>
            <Grid item padding={1}>
                <Button variant="outlined" onClick={() => resendOtp()}>Resend</Button>
            </Grid>
            <Grid>
                <Button variant="outlined" color="success" onClick={() => handleVerifyOtp()}>Verify OTP</Button>
            </Grid>
        </Grid>
        </Box>
        </Container>
        <footer>
        <svg viewBox="0 -20 700 110" width="100%" height="110" preserveAspectRatio="none">
            <path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="var(--teal-100)" opacity="0.8" />
            <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="var(--teal-A700)" />
        </svg>
    </footer>
        </>
    )
}
export default Otp;