import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import { Statement } from "../../../untils/icons";
import dayjs from "dayjs";
import FinalcialTable from "./FinancialTable";
import { headerTable } from "../../../untils/static";

require("dayjs/locale/th");

function FinancialList() {
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <Statement fontSize="medium" />
                            <Typography className="main-header">รายการเงินสด</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} className="flex-end">
                        <Typography>วันที่ {dayjs().locale("th").format("D MMMM BBBB เวลา HH:mm น.")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} mt={3}>
                        <Typography>รายรับ/รายจ่าย</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} mt={3}>
                        <Typography sx={{ cursor: "pointer" }}>จำนวนเงินรวมทั้งสิ้น </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} my={2}>
                    {/* <FinalcialTable header={headerTable.FinancialStatement} /> */}
                </Grid>
            </Paper>
        </Box>
    );
}

export default FinancialList;
