import { Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";

import { HospitalIcon } from "../../../untils/icons";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { headerTable } from "../../../untils/static";
import GridTableList from "./GridTableList";

function ListHospital(props) {
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12}>
                    <Grid item xs={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <HospitalIcon fontSize="medium" />
                            <Typography className="main-header">สถานะการดึงข้อมูล</Typography>
                        </Stack>
                    </Grid>
                    <Grid container columns={12} spacing={2} className="flex-start" width={{ xs: "100%", sm: "80%", md: "50%" }}>
                        <Grid item xs={12} sm={7} md={7} my={2}>
                            <TextField
                                fullWidth
                                id="search"
                                label="ค้นหารายการ"
                                type="text"
                                variant="outlined"
                                size="medium"
                                value={props.search}
                                onChange={(e) => {
                                    props.setSearch(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    defaultValue={props.dateFilter}
                                    value={props.dateFilter}
                                    slotProps={{
                                        field: { clearable: true, onClear: () => props.setDateFilter(null) },
                                    }}
                                    name="dateFilter"
                                    label="วัน/เดือน/ปี"
                                    format="DD/MM/YYYY"
                                    onChange={(e) => {
                                        props.setDateFilter(e);
                                        // console.log({ e });
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} my={2}>
                        <GridTableList header={headerTable.hospital_status} data={props.data} />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default ListHospital;
