import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ModalStepper from "../../components/ModalStepper";
import { useRoute } from "../../state/ManageRoute";
import { ItemHomepage } from "../../Theme";
import { ClaimIcon, Dashboard, DatabaseIcn, StockPage } from "../../untils/icons";

function Homepage() {
     const navigate = useNavigate();
     const screen = JSON.parse(localStorage.getItem("screen"));
     // console.log(screen);
     let intersections_home = screen.filter((e) => e.type === 0);
     let intersections_stock = screen.filter((e) => e.type === 1);
     let intersections_claim = screen.filter((e) => e.type === 2);
     let intersections_dashboard = screen.filter((e) => e.type === 3);
     let intersections_database = screen.filter((e) => e.type === 4);

     const [setMenu, setSystem] =
          useRoute((state) => [
               state.setMenu,
               state.setSystem,
          ]);

     const handleRoute = (path, menu, system) => {
          // console.log('path', path);
          // console.log('menu', menu);
          // console.log('system', system);
          let objMenu = menu[0];

          setMenu(menu)
          setSystem(system)
          
          if (objMenu.sub) {
               navigate((objMenu.submenu[0].url));
               // navigate((objMenu.submenu[0].url).replace(/\u200B/g, ''));
          } else {
               navigate(path);
          }
     };
     const popup = window.localStorage.getItem("popup");
     const [open, setopen] = useState(popup ? dayjs(new Date()).format("YYYY-MM-DD") >= popup : true);

     useEffect(() => {
          setMenu(intersections_home);
          setSystem("หน้าหลัก");
     }, []);

     return (
          <>
               <Container maxWidth={false} sx={{ position: "relative" }}>
                    <Modal open={open} onClose={() => setopen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                         <Box
                              sx={{
                                   position: "absolute",
                                   top: "50%",
                                   left: "50%",
                                   transform: "translate(-50%, -50%)",
                                   minWidth: 350,
                                   maxWidth: 1200,
                                   bgcolor: "background.paper",
                                   borderRadius: "5px",
                                   boxShadow: 24,
                                   p: 4,
                              }}
                         >
                              <ModalStepper handleClose={() => setopen(false)} />
                         </Box>
                    </Modal>
                    <Box className="flex-center" height="80vh">
                         <Grid container columns={12} className="flex-center" spacing={3}>
                              <Grid item xs={12}>
                                   <Stack className="flex-center" spacing={3} direction={{ xs: "column", sm: "column", md: "row" }}>
                                        {intersections_stock.length > 0 && (
                                             <ItemHomepage
                                                  elevation={3}
                                                  className="flex-center"
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                       handleRoute(`${intersections_stock[0].url}`, intersections_stock, "ระบบคลังยา");
                                                  }}
                                             >
                                                  <Stack direction="row" spacing={1} className="flex-start">
                                                       <StockPage />
                                                       <Typography className="menu-homepage">ระบบคลังยา</Typography>
                                                  </Stack>
                                             </ItemHomepage>
                                        )}
                                        {intersections_claim.length > 0 && (
                                             <ItemHomepage
                                                  elevation={3}
                                                  className="flex-center"
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                       handleRoute(`${intersections_claim[0].url}`, intersections_claim, "ระบบสนับสนุนการเคลม");
                                                  }}
                                             >
                                                  <Stack direction="row" spacing={1} className="flex-start">
                                                       <ClaimIcon />
                                                       <Typography className="menu-homepage">ระบบสนับสนุนการเคลม</Typography>
                                                  </Stack>
                                             </ItemHomepage>
                                        )}
                                        {intersections_dashboard.length > 0 && (
                                             <ItemHomepage
                                                  elevation={3}
                                                  className="flex-center"
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                       handleRoute(
                                                            `${intersections_dashboard[0].url}`,
                                                            intersections_dashboard,
                                                            "ระบบ Dashboard"
                                                       );
                                                  }}
                                             >
                                                  <Stack direction="row" spacing={1} className="flex-start">
                                                       <Dashboard />
                                                       <Typography className="menu-homepage">ระบบ Dashboard</Typography>
                                                  </Stack>
                                             </ItemHomepage>
                                        )}
                                        {intersections_database.length > 0 && (
                                             <ItemHomepage
                                                  elevation={3}
                                                  className="flex-center"
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                       handleRoute(
                                                            `${intersections_database[0].url}`,
                                                            intersections_database,
                                                            "ฐานข้อมูลประชากร"
                                                       );
                                                  }}
                                             >
                                                  <Stack direction="row" spacing={1} className="flex-start">
                                                       <DatabaseIcn />
                                                       <Typography className="menu-homepage">ฐานข้อมูลประชากร</Typography>
                                                  </Stack>
                                             </ItemHomepage>
                                        )}
                                   </Stack>
                              </Grid>
                         </Grid>
                    </Box>
               </Container>
               <footer>
                    <svg viewBox="0 -20 700 110" width="100%" height="110" preserveAspectRatio="none">
                         <path
                              transform="translate(0, -20)"
                              d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700"
                              fill="var(--teal-100)"
                              opacity="0.8"
                         />
                         <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="var(--teal-A700)" />
                    </svg>
               </footer>
          </>
     );
}

export default Homepage;
