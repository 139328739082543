import React, { useEffect, useMemo, useState } from "react";

import { Container, debounce } from "@mui/material";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GETHOSPITALSTATUS, POST } from "../../service";
import ListHospital from "./ListHospital/ListHospital";

function HospitalStatus() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [dateFilter, setDateFilter] = useState(dayjs());

    const getHospitalStatus = async () => {
        try {
            // setLoading(true);
            let res = await POST(GETHOSPITALSTATUS, { date: dateFilter, search });
            if (res.success) {
                setData(res.result);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "warning",
                text: `${error}`,
                confirmButtonText: "ตกลง",
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(false);
                }
            });
        }
    };

    const fetch = useMemo(
        () =>
            debounce(async (request) => {
                try {
                    // setLoading(true);
                    const payload = {
                        search: request,
                    };
                    let res = await POST(GETHOSPITALSTATUS, payload);
                    if (res.success) {
                        setData(res.result);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        Swal.fire({
                            icon: "warning",
                            text: `${res.message}`,
                            showConfirmButton: true,
                        });
                    }
                } catch (error) {
                    setLoading(false);
                    Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
                }
            }, 400),
        []
    );

    useEffect(() => {
        // console.log({ search, s: search.length });
        //let active = true;

        if (search.length < 2) {
            // getHospitalStatus();
            return;
        } else if (!search) {
            // getHospitalStatus();
            return;
        }

        fetch(search);

        // return () => {
        //     active = false;
        // };
    }, [search, fetch]);

    useEffect(() => {
        if (dateFilter) {
            // console.log({dateFilter,row});
            setData((prev) => prev.filter((e) => dayjs(e.date).isSame(dayjs(dateFilter, "day"))));
        }
    }, [dateFilter]);

    useEffect(() => {
        getHospitalStatus();
    }, [search, dateFilter]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <ListHospital data={data} setData={setData} search={search} setSearch={setSearch} dateFilter={dateFilter} setDateFilter={setDateFilter} />
        </Container>
    );
}

export default HospitalStatus;
