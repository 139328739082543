import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ItemDashboard } from "../../Theme";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { GETDASHBOARDDATA, POST } from "../../service";
import { Dashboard, LocaIcn, LocalHotelIcn, MenIcn, PeopleIcn, WomenIcn } from "../../untils/icons";
// import subdistrictData from './components/rewound-geojson.json'; // Import your district GeoJSON data
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { fixAmount } from "../../untils/shortcut";
import { headerTable } from "../../untils/static";
import BarChartDashboard from "./Dashboard16/BarChartDashboard";
import DoughnutChart from "./Dashboard16/DoughnutChart";
import GridTable from "./Dashboard16/GridTable";
import LeafletMapComponent from "./Dashboard16/LeafletMapComponent";

function DashboardAll() {
    const [dataValueService, setDataValueService] = useState([]);
    // const [dataTambon, setDataTambon] = useState([]);
    const [countHNAME, setCountHNAME] = useState([]);
    const [subFunds, setSubFunds] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dateStart, setDateStart] = useState(dayjs(new Date()).startOf("month").utc(true));
    const [dateEnd, setDateEnd] = useState(dayjs(new Date()));
    const [cleared, setCleared] = useState(false);
    const [selectTambon, setSelectTambon] = useState("");
    const [selectINSCL, setSelectINSCL] = useState("");
    const [dataLocation, setDataLocation] = useState([]);
    const [selectLocation, setSelectLocation] = useState("");
    const [dataInscl, setDataInscl] = useState([]);
    const [subdistrictObj, setSubdistrictObj] = useState([]);

    // const sub_data = [];
    // const subdistrict = subdistrictData.features.filter((e) => e.properties.pro_code === "40")
    // subdistrict.map((e, i) =>
    //     sub_data.push({ ...e, properties: { ...e.properties, count: `${i + 1}00` } })
    // )
    // const [subdistrictObjTest, setSubdistrictObjTest] = useState(sub_data);

    const GetDataDashboard = async () => {
        try {
            setLoading(true);
            const payload = { tambonname: selectTambon, INSCL: selectINSCL, HNAME: selectLocation, date_start: dateStart, date_end: dateEnd };
            // console.log("payload", payload);
            let res = await POST(GETDASHBOARDDATA, payload);
            if (res.success) {
                setDataLocation(res.result.hname);
                setDataInscl(res.result.inscl);
                setDataValueService(res.result);
                setCountHNAME(res.result.countHNAME);
                setSubFunds(res.result.countNhso);
                setDataTable(res.result.countNhsoHname);
                setSubdistrictObj(res.result.dataTambon.features);
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    const handleSelectINSCL = (service) => {
        setSelectINSCL(service);
    };

    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    useEffect(() => {
        // GetAllTambon();
        GetDataDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTambon, selectINSCL, selectLocation, dateStart, dateEnd]);

    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <BackdropLoad loading={loading} />
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={5}>
                        <Grid item xs={12} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">รายงานการใช้หน่วยบริการในจังหวัด</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} className="flex-start">
                            <Box className="box-info" width={{ xs: "100%", sm: "auto" }}>
                                <Stack direction="row" className="flex-start" spacing={3}>
                                    <LocaIcn transform="scale(2)" />
                                    <Grid>
                                        <Typography fontWeight="bold" color="#fff">
                                            รพ. สต. ทั้งหมด (แห่ง)
                                        </Typography>
                                        <Typography className="font-dashboard-white">{dataValueService.countHcode}</Typography>
                                    </Grid>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} className="flex-center">
                            <Grid container columns={12} spacing={3} display="flex" justifyContent={{ xs: "flex-start", sm: "flex-end" }}>
                                <Grid item xs={6} sm={4} md={2}>
                                    <Box className={selectINSCL === "" ? "box-info-active" : "box-info"} width="100%">
                                        <Button className="target" variant="text">
                                            <Typography
                                                sx={{ textDecoration: selectINSCL === "" && "underline" }}
                                                className="font-dashboard-white"
                                                onClick={(e) => handleSelectINSCL("")}
                                            >
                                                ทั้งหมด
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                {dataInscl &&
                                    dataInscl.map((ele, ind) => (
                                        <Grid ind={ind + 1} item xs={6} sm={4} md={2} className="flex-center">
                                            <Box
                                                className={selectINSCL === ele.inscl ? "box-info-active" : "box-info"}
                                                width="100%"
                                                fullWidth
                                                onClick={(e) => handleSelectINSCL(ele.inscl)}
                                            >
                                                <Button className="target" variant="text">
                                                    <Typography
                                                        sx={{ textDecoration: selectINSCL === ele.inscl && "underline" }}
                                                        className="font-dashboard-white"
                                                    >
                                                        {ele.inscl}
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                    ))}
                                {/* <Grid item xs={6} sm={4} md={2}>
                                    <Box className={selectINSCL === "LGO" ? "box-info-active" : "box-info"} width="100%">
                                        <Button className="target" variant="text" onClick={(e) => handleSelectINSCL("LGO")}>
                                            <Typography sx={{ textDecoration: selectINSCL === "LGO" && "underline" }} className="font-dashboard-white" >LGO</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} md={2} className="flex-center">
                                    <Box className={selectINSCL === "OFC" ? "box-info-active" : "box-info"} width="100%">
                                        <Button className="target" fullWidth variant="text" onClick={(e) => handleSelectINSCL("OFC")}>
                                            <Typography sx={{ textDecoration: selectINSCL === "OFC" && "underline" }} className="font-dashboard-white" >OFC</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} md={2} className="flex-center">
                                    <Box className={selectINSCL === "SSS" ? "box-info-active" : "box-info"} width="100%">
                                        <Button className="target" variant="text" fullWidth onClick={(e) => handleSelectINSCL("SSS")}>
                                            <Typography sx={{ textDecoration: selectINSCL === "SSS" && "underline" }} className="font-dashboard-white" >SSS</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={4} md={2} className="flex-center">
                                    <Box className={selectINSCL === "UCS" ? "box-info-active" : "box-info"} width="100%" fullWidth onClick={(e) => handleSelectINSCL("UCS")}>
                                        <Button className="target" variant="text">
                                            <Typography sx={{ textDecoration: selectINSCL === "UCS" && "underline" }} className="font-dashboard-white" >UCS</Typography>
                                        </Button>
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={6} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateStart}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่เริ่มต้น"
                                                format="DD/MM/YYYY"
                                                onChange={(e) => setDateStart(e)}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateEnd}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่สิ้นสุด"
                                                format="DD/MM/YYYY"
                                                onChange={(e) => setDateEnd(e)}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>สถานพยาบาล</InputLabel>
                                        <Select
                                            name="Tambon"
                                            value={selectLocation}
                                            label="เลือกตำบล"
                                            required
                                            onChange={(e) => setSelectLocation(e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="">ทั้งหมด</MenuItem>
                                            {dataLocation &&
                                                dataLocation.map((el, i) => (
                                                    <MenuItem key={i + 1} value={el.hname}>
                                                        {el.hname}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="flex-start">
                            <Stack width="100%" direction={{ xs: "column", sm: "column", md: "row" }} spacing={3}>
                                <ItemDashboard elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <PeopleIcn transform="scale(2)" />
                                        <Grid>
                                            <Typography className="font-dashboard">การเข้ารับบริการ (คน)</Typography>
                                            <Typography className="font-dashboard">
                                                {fixAmount(dataValueService.countPerson ? dataValueService.countPerson : 0)}
                                            </Typography>
                                        </Grid>
                                    </Stack>
                                </ItemDashboard>
                                <ItemDashboard elevation={3}>
                                    <Grid container columns={12}>
                                        <Grid item xs={6}>
                                            <Stack direction="row" className="flex-start" spacing={3}>
                                                <MenIcn transform="scale(2)" />
                                                <Grid>
                                                    <Typography className="font-dashboard">เพศชาย (คน)</Typography>
                                                    <Typography className="font-dashboard">
                                                        {fixAmount(dataValueService.countPersonMan ? dataValueService.countPersonMan : 0)}
                                                    </Typography>
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack direction="row" className="flex-start" spacing={3}>
                                                <WomenIcn transform="scale(2)" />
                                                <Grid>
                                                    <Typography className="font-dashboard">เพศหญิง (คน)</Typography>
                                                    <Typography className="font-dashboard">
                                                        {fixAmount(dataValueService.countPersonWoman ? dataValueService.countPersonWoman : 0)}
                                                    </Typography>
                                                </Grid>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </ItemDashboard>
                                <ItemDashboard elevation={3}>
                                    <Stack direction="row" className="flex-start" spacing={{ xs: 3, sm: 5 }}>
                                        <LocalHotelIcn transform="scale(2)" />
                                        <Grid>
                                            <Typography className="font-dashboard">การเข้ารับบริการ (ครั้ง)</Typography>
                                            <Typography className="font-dashboard">
                                                {fixAmount(dataValueService.countService ? dataValueService.countService : 0)}
                                            </Typography>
                                        </Grid>
                                    </Stack>
                                </ItemDashboard>
                            </Stack>
                        </Grid>
                        {/* MAP */}
                        {/* <Grid item xs={12} >
                            <Grid container columns={12} spacing={3} className='flex-center'>
                                <Grid item xs={12} sm={12} md={6}>
                                    <LeafletMapComponent subdistrictObj={subdistrictObj} setSelectTambon={setSelectTambon} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <Stack spacing={5}>
                                        <BarChartTreatment information={countService} />
                                        <HorizontalBars information={countHNAME} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item xs={12} className="flex-center">
                            <Grid container columns={12} spacing={3} >
                                <Grid item xs={12} sm={6} md={3}>
                                    <ItemDashboard elevation={3}>
                                        <Stack className="flex-center" spacing={2}>
                                            <Typography className="font-dashboard">{tofieds(selectINSCL === "" ? dataValueService.amountClaimSSI : selectINSCL === "SSI" ? dataValueService.amountClaimSSI : 0)}</Typography>
                                            <Typography className="font-dashboard">มูลค่าการเคลมสิทธิ SSI</Typography>
                                        </Stack>
                                    </ItemDashboard>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className="flex-center">
                                    <ItemDashboard elevation={3}>
                                        <Stack className="flex-center" spacing={2}>
                                            <Typography className="font-dashboard">{tofieds(selectINSCL === "" ? dataValueService.amountClaimOFC : selectINSCL === "OFC" ? dataValueService.amountClaimOFC : 0)}</Typography>
                                            <Typography className="font-dashboard">มูลค่าการเคลมสิทธิ OFC</Typography>
                                        </Stack>
                                    </ItemDashboard>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className="flex-center">
                                    <ItemDashboard elevation={3}>
                                        <Stack className="flex-center" spacing={2}>
                                            <Typography className="font-dashboard">{tofieds(selectINSCL === "" ? dataValueService.amountClaimSSS : selectINSCL === "SSS" ? dataValueService.amountClaimSSS : 0)}</Typography>
                                            <Typography className="font-dashboard">มูลค่าการเคลมสิทธิ SSS</Typography>
                                        </Stack>
                                    </ItemDashboard>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} className="flex-center">
                                    <ItemDashboard elevation={3} >
                                        <Stack className="flex-center" spacing={2}>
                                            <Typography className="font-dashboard">{tofieds(selectINSCL === "" ? dataValueService.amountClaimUCS : selectINSCL === "UCS" ? dataValueService.amountClaimUCS : 0)}</Typography>
                                            <Typography className="font-dashboard">มูลค่าการเคลมสิทธิ UCS</Typography>
                                        </Stack>
                                    </ItemDashboard>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item xs={12} >
                            <Grid container columns={12} spacing={3} className='flex-center'>
                                <Grid item xs={12} sm={12} md={6}>
                                    <LeafletMapComponent subdistrictObj={subdistrictObj} setSelectTambon={setSelectTambon} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <BarChartDashboard information={countHNAME} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container columns={12} spacing={3} >
                                <Grid item xs={12} sm={12} md={6}>
                                    <DoughnutChart />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} >
                                    <GridTable header={headerTable.dashboard_chart} data={dataTable} />
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={12} md={12} >
                            <GridTable header={headerTable.dashboard_chart} data={dataTable} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <LeafletMapComponent subdistrictObj={subdistrictObj} setSelectTambon={setSelectTambon} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container columns={12} spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <BarChartDashboard information={countHNAME} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <DoughnutChart information={subFunds} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <GridTable header={headerTable.dashboard_chart} data={dataTable} />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}

export default DashboardAll;
