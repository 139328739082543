import { Box, Card, CardContent, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { BtnLoading, BtnLoadingRed } from "../../../Theme.jsx";
import PersonTable from "./PersonTable.js";

function PersonCard({
    person_id,
    text,
    setText,
    setIdNumber,
    handleSearch,
    handleReset,
    loadingBtn,
    data,
    header,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    page,
    lengthData,
}) {
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={3}>
                    <Grid item xs={12} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine">
                                <Stack spacing={2} width="100%">
                                    <Typography className="main-header">ฐานข้อมูลประชากร</Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="เลขบัตรประชาชน"
                                                name="number"
                                                type="tel"
                                                value={person_id}
                                                variant="outlined"
                                                onChange={(e) => setIdNumber(e.currentTarget.value)}
                                                inputProps={{ maxLength: 13 }}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="ชื่อ-นามสกุล"
                                                name="fullname"
                                                type="text"
                                                value={text}
                                                variant="outlined"
                                                onChange={(e) => setText(e.currentTarget.value)}
                                            />
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2.5}>
                                            <BtnLoading fullWidth onClick={handleSearch} loading={loadingBtn} sx={{ p: 1 }}>
                                                ค้นหาข้อมูล
                                            </BtnLoading>
                                        </Grid>
                                        <Grid item my={2} xs={4} sm={2} md={2.5} ml={2}>
                                            <BtnLoadingRed fullWidth onClick={handleReset} loading={loadingBtn} sx={{ p: 1 }}>
                                                รีเซ็ต
                                            </BtnLoadingRed>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        <PersonTable
                                            data={data}
                                            header={header}
                                            handleChangePage={handleChangePage}
                                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            lengthData={lengthData}
                                        />
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default PersonCard;
