import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad.js";
import { POST, SEARCHINSCL } from "../../service.js";
import { headerTable } from "../../untils/static.js";
import INSCLCard from "./INSCL/INSCLCard.js";

function INSCL() {
    const location = useLocation();
    const { detail } = location.state;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [search, setSearch] = useState(detail.CID);
    const [message, setMessage] = useState([]);

    const handleGetINSCL = async () => {
        try {
            setLoading(true);
            const res = await POST(SEARCHINSCL, { person_id: detail.CID });
            if (res.success) {
                // console.log(res.result);
                if (res.result.message === "ค้นหาสำเร็จ") {
                    setMessage(res.result.message);
                    setData(res.result.data);
                } else {
                    setMessage(res.result.message);
                    Swal.fire({
                        icon: "warning",
                        text: `${res.result.message}`,
                        confirmButtonText: "ตกลง",
                    });
                }
                setLoading(false);
            } else {
                setLoading(false);
                Swal.fire({
                    icon: "warning",
                    text: `${res.message}`,
                    confirmButtonText: "ตกลง",
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({ title: "warning", text: error, icon: "warning", showConfirmButton: true });
        }
    };

    useEffect(() => {
        handleGetINSCL();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <BackdropLoad loading={loading} />
            <Container maxWidth="xl" sx={{ position: "relative" }}>
                <INSCLCard data={data} message={message} header={headerTable.INSCL_header} setSearch={setSearch} search={search} />
            </Container>
        </>
    );
}

export default INSCL;
