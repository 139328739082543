import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { BtnLoading, ButtonTealSubmit } from "../../../../Theme";

function ClaimCodeCard({
    emailEclaim,
    setEmailEclaim,
    passwordEclaim,
    setPasswordEclaim,
    showPasswordEClaim,
    handleClickShowPasswordEClaim,
    handleMouseDownPasswordEClaim,
    getTokenEClaim,
    loadingBtnEclaim,
    loadingBtnMoph,
    loadingBtnSSS,
    msgEclaim,
    statusEclaim,
    emailMoph,
    setEmailMoph,
    passwordMoph,
    setPasswordMoph,
    showPasswordMoph,
    handleClickShowPasswordMoph,
    handleMouseDownPasswordMoph,
    getTokenMClaim,
    msgMoph,
    statusMoph,
    emailSSS,
    setEmailSSS,
    passwordSSS,
    setPasswordSSS,
    showPasswordSSS,
    handleClickShowPasswordSSS,
    handleMouseDownPasswordSSS,
    getTokenMSSS,
    msgSSS,
    statusSSS,
    handleClickShowPasswordSeamless,
    handleMouseDownPasswordSeamless,
    showPasswordSeamless,
    setShowPasswordSeamless,
    handleSubmitEclaim,
    handleSubmitMophClaim,
    handleSubmitSSS,
    handleSubmitSeamless,
    usernameSeamless,
    setUsernameSeamless,
    passwordSeamless,
    setPasswordSeamless,
    getTokenSeamless,
    loadingBtnSeamless,
    msgSeamless,
    statusSeamless,
    usernameHTK,
    passwordHTK,
    setUsernameHTK,
    setPasswordHTK,
    handleClickShowPasswordHTK,
    handleMouseDownPasswordHTK,
    handleSubmitHTK,
    showPasswordHTK,
    loadingBtnHTK,
    statusHTK,
    msgHTK,
    getTokenHTK,
    handleClickShowPasswordNewAuthCode,
    setPasswordNewAuthCode,
    setUsernameNewAuthCode,
    getNewAuthCode,
    handleSubmitNewAuthCode,
    usernameNewAuthCode,
    passwordNewAuthCode,
    statusNewAuthCode,
    msgNewAuthCode,
    loadingBtnNewAuthCode,
    showPasswordNewAuthCode,
    handleMouseDownPasswordNewAuthCode,
    mobileAPI,
    setMobileAPI,
}) {
    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={3}>
                    <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine">
                                <Stack spacing={2} width="100%">
                                    <Typography
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => window.open("https://eclaim.nhso.go.th/Client/login")}
                                        className="main-header"
                                    >
                                        E claim
                                    </Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="ชื่อผู้ใช้งาน"
                                                name="email"
                                                type="text"
                                                value={emailEclaim}
                                                variant="outlined"
                                                onChange={(e) => setEmailEclaim(e.currentTarget.value)}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                <OutlinedInput
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    value={passwordEclaim}
                                                    variant="outlined"
                                                    onChange={(e) => setPasswordEclaim(e.currentTarget.value)}
                                                    type={showPasswordEClaim ? "text" : "password"}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPasswordEClaim}
                                                                onMouseDown={handleMouseDownPasswordEClaim}
                                                                edge="end"
                                                            >
                                                                {showPasswordEClaim ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2}>
                                            <BtnLoading
                                                onClick={getTokenEClaim}
                                                fullWidth
                                                loading={loadingBtnEclaim}
                                                disabled={loadingBtnMoph || loadingBtnSSS || loadingBtnMoph || loadingBtnHTK || loadingBtnNewAuthCode}
                                            >
                                                <span>
                                                    ตรวจสอบ <br />
                                                    รหัสใช้งาน
                                                </span>
                                            </BtnLoading>
                                        </Grid>
                                        {msgEclaim && (
                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                <Typography>:</Typography>
                                            </Grid>
                                        )}
                                        <Grid item my={2} xs={7} sm={9} md={4}>
                                            <Typography color={statusEclaim === true ? "green" : "red"}>{msgEclaim}</Typography>
                                        </Grid>
                                        <Grid item my={2} xs={4} sm={2} md={5} className="flex-end">
                                            <ButtonTealSubmit onClick={handleSubmitEclaim} disabled={statusEclaim !== true}>
                                                บันทึกข้อมูล
                                            </ButtonTealSubmit>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine">
                                <Stack spacing={2} width="100%">
                                    <Typography
                                        className="main-header"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => window.open("https://cvp1.moph.go.th/dashboard/")}
                                    >
                                        Moph claim
                                    </Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="ชื่อผู้ใช้งาน"
                                                name="email"
                                                type="text"
                                                value={emailMoph}
                                                variant="outlined"
                                                onChange={(e) => setEmailMoph(e.currentTarget.value)}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                <OutlinedInput
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    value={passwordMoph}
                                                    variant="outlined"
                                                    onChange={(e) => setPasswordMoph(e.currentTarget.value)}
                                                    type={showPasswordMoph ? "text" : "password"}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPasswordMoph}
                                                                onMouseDown={handleMouseDownPasswordMoph}
                                                                edge="end"
                                                            >
                                                                {showPasswordMoph ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2}>
                                            <BtnLoading
                                                onClick={getTokenMClaim}
                                                fullWidth
                                                loading={loadingBtnMoph}
                                                disabled={
                                                    loadingBtnEclaim || loadingBtnSSS || loadingBtnMoph || loadingBtnHTK || loadingBtnNewAuthCode
                                                }
                                            >
                                                <span>
                                                    ตรวจสอบ <br />
                                                    รหัสใช้งาน
                                                </span>
                                            </BtnLoading>
                                        </Grid>
                                        {msgMoph && (
                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                <Typography>:</Typography>
                                            </Grid>
                                        )}
                                        <Grid item my={2} xs={7} sm={9} md={4}>
                                            <Typography color={statusMoph === true ? "green" : "red"}>{msgMoph}</Typography>
                                        </Grid>
                                        <Grid item my={2} xs={4} sm={2} md={5} className="flex-end">
                                            <ButtonTealSubmit onClick={handleSubmitMophClaim} disabled={statusMoph !== true}>
                                                บันทึกข้อมูล
                                            </ButtonTealSubmit>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine">
                                <Stack spacing={2} width="100%">
                                    <Typography
                                        className="main-header"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => window.open("https://cs5.chi.or.th/ssopupload")}
                                    >
                                        เคลมประกันสังคม
                                    </Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="ชื่อผู้ใช้งาน"
                                                name="email"
                                                type="text"
                                                value={emailSSS}
                                                variant="outlined"
                                                onChange={(e) => setEmailSSS(e.currentTarget.value)}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                <OutlinedInput
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    value={passwordSSS}
                                                    variant="outlined"
                                                    onChange={(e) => setPasswordSSS(e.currentTarget.value)}
                                                    type={showPasswordSSS ? "text" : "password"}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPasswordSSS}
                                                                onMouseDown={handleMouseDownPasswordSSS}
                                                                edge="end"
                                                            >
                                                                {showPasswordSSS ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2}>
                                            <BtnLoading
                                                onClick={getTokenMSSS}
                                                fullWidth
                                                loading={loadingBtnSSS}
                                                disabled={
                                                    loadingBtnEclaim || loadingBtnMoph || loadingBtnSeamless || loadingBtnHTK || loadingBtnNewAuthCode
                                                }
                                            >
                                                <span>
                                                    ตรวจสอบ <br />
                                                    รหัสใช้งาน
                                                </span>
                                            </BtnLoading>
                                        </Grid>
                                        {msgSSS && (
                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                <Typography>:</Typography>
                                            </Grid>
                                        )}
                                        <Grid item my={2} xs={7} sm={9} md={4}>
                                            <Typography color={statusSSS === true ? "green" : "red"}>{msgSSS}</Typography>
                                        </Grid>
                                        <Grid item my={2} xs={4} sm={2} md={5} className="flex-end">
                                            <ButtonTealSubmit onClick={handleSubmitSSS} disabled={statusSSS !== true}>
                                                บันทึกข้อมูล
                                            </ButtonTealSubmit>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine">
                                <Stack spacing={2} width="100%">
                                    <Typography
                                        className="main-header"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => window.open("https://seamlessfordmis.nhso.go.th/seamlessfordmis/faces/login.jsf")}
                                    >
                                        Seamless For DMIS
                                    </Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="ชื่อผู้ใช้งาน"
                                                name="username"
                                                type="text"
                                                value={usernameSeamless}
                                                variant="outlined"
                                                onChange={(e) => setUsernameSeamless(e.currentTarget.value)}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                <OutlinedInput
                                                    required
                                                    fullWidth
                                                    name="password"
                                                    value={passwordSeamless}
                                                    variant="outlined"
                                                    onChange={(e) => setPasswordSeamless(e.currentTarget.value)}
                                                    type={showPasswordSSS ? "text" : "password"}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPasswordSeamless}
                                                                onMouseDown={handleMouseDownPasswordSeamless}
                                                                edge="end"
                                                            >
                                                                {showPasswordSeamless ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2}>
                                            <BtnLoading
                                                onClick={getTokenSeamless}
                                                fullWidth
                                                loading={loadingBtnSeamless}
                                                disabled={
                                                    loadingBtnEclaim || loadingBtnMoph || loadingBtnSSS || loadingBtnHTK || loadingBtnNewAuthCode
                                                }
                                            >
                                                <span>
                                                    ตรวจสอบ <br />
                                                    รหัสใช้งาน
                                                </span>
                                            </BtnLoading>
                                        </Grid>
                                        {msgSeamless && (
                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                <Typography>:</Typography>
                                            </Grid>
                                        )}
                                        <Grid item my={2} xs={7} sm={9} md={4}>
                                            <Typography color={statusSeamless === true ? "green" : "red"}>{msgSeamless}</Typography>
                                        </Grid>
                                        <Grid item my={2} xs={4} sm={2} md={5} className="flex-end">
                                            <ButtonTealSubmit onClick={handleSubmitSeamless} disabled={statusSeamless !== true}>
                                                บันทึกข้อมูล
                                            </ButtonTealSubmit>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine">
                                <Stack spacing={2} width="100%">
                                    <Typography
                                        className="main-header"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => window.open("https://www.healthplatform.krungthai.com/healthPlatform/login")}
                                    >
                                        Health Platform Krungthai
                                    </Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <TextField
                                                fullWidth
                                                label="ชื่อผู้ใช้งาน"
                                                name="username"
                                                type="text"
                                                value={usernameHTK}
                                                variant="outlined"
                                                onChange={(e) => setUsernameHTK(e.currentTarget.value)}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    name="password"
                                                    value={passwordHTK}
                                                    variant="outlined"
                                                    onChange={(e) => setPasswordHTK(e.currentTarget.value)}
                                                    type={showPasswordSSS ? "text" : "password"}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPasswordHTK}
                                                                onMouseDown={handleMouseDownPasswordHTK}
                                                                edge="end"
                                                            >
                                                                {showPasswordHTK ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2}>
                                            <BtnLoading
                                                onClick={getTokenHTK}
                                                fullWidth
                                                loading={loadingBtnHTK}
                                                disabled={
                                                    loadingBtnEclaim || loadingBtnMoph || loadingBtnSSS || loadingBtnSeamless || loadingBtnNewAuthCode
                                                }
                                            >
                                                <span>
                                                    ตรวจสอบ <br />
                                                    รหัสใช้งาน
                                                </span>
                                            </BtnLoading>
                                        </Grid>
                                        {msgHTK && (
                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                <Typography>:</Typography>
                                            </Grid>
                                        )}
                                        <Grid item my={2} xs={7} sm={9} md={4}>
                                            <Typography color={statusHTK === true ? "green" : "red"}>{msgHTK}</Typography>
                                        </Grid>
                                        <Grid item my={2} xs={4} sm={2} md={5} className="flex-end">
                                            <ButtonTealSubmit onClick={handleSubmitHTK} disabled={statusHTK !== true}>
                                                บันทึกข้อมูล
                                            </ButtonTealSubmit>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} my={3} className="flex-center">
                        <Card className="box-card-vaccine">
                            <CardContent className="box-card-vaccine">
                                <Stack spacing={2} width="100%">
                                    <Typography
                                        className="main-header"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                        onClick={() => window.open("https://authenservice.nhso.go.th/authencode/#/login")}
                                    >
                                        New Auth Code
                                    </Typography>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <TextField
                                                fullWidth
                                                label="ชื่อผู้ใช้งาน"
                                                name="username"
                                                type="text"
                                                value={usernameNewAuthCode}
                                                variant="outlined"
                                                onChange={(e) => setUsernameNewAuthCode(e.currentTarget.value)}
                                            />
                                        </Grid>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    name="password"
                                                    value={passwordNewAuthCode}
                                                    variant="outlined"
                                                    onChange={(e) => setPasswordNewAuthCode(e.currentTarget.value)}
                                                    type={showPasswordNewAuthCode ? "text" : "password"}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPasswordNewAuthCode}
                                                                onMouseDown={handleMouseDownPasswordNewAuthCode}
                                                                edge="end"
                                                            >
                                                                {showPasswordNewAuthCode ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Stack>
                                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
                                        <Grid item my={2} xs={12} sm={12} md={12}>
                                            <TextField
                                                fullWidth
                                                disabled={statusNewAuthCode === true}
                                                label="Mobile API"
                                                name="mobile_api"
                                                type="text"
                                                value={mobileAPI}
                                                variant="outlined"
                                                onChange={(e) => setMobileAPI(e.currentTarget.value)}
                                            />
                                        </Grid>
                                    </Stack>
                                    <Grid container columns={12} className="flex-start">
                                        <Grid item my={2} xs={4} sm={2} md={2}>
                                            <BtnLoading
                                                onClick={getNewAuthCode}
                                                fullWidth
                                                loading={loadingBtnNewAuthCode}
                                                disabled={loadingBtnEclaim || loadingBtnMoph || loadingBtnSSS || loadingBtnSeamless || loadingBtnHTK}
                                            >
                                                <span>
                                                    ตรวจสอบ <br />
                                                    รหัสใช้งาน
                                                </span>
                                            </BtnLoading>
                                        </Grid>
                                        {msgNewAuthCode && (
                                            <Grid item my={2} xs={1} sm={1} md={1} className="flex-center">
                                                <Typography>:</Typography>
                                            </Grid>
                                        )}
                                        <Grid item my={2} xs={7} sm={9} md={4}>
                                            <Typography color={statusNewAuthCode === true ? "green" : "red"}>{msgNewAuthCode}</Typography>
                                        </Grid>
                                        <Grid item my={2} xs={4} sm={2} md={5} className="flex-end">
                                            <ButtonTealSubmit onClick={handleSubmitNewAuthCode} disabled={statusNewAuthCode !== true}>
                                                บันทึกข้อมูล
                                            </ButtonTealSubmit>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default ClaimCodeCard;
