import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Swal from "sweetalert2";
import BackdropLoad from "../../components/Backdrop/BackdropLoad";
import { getToken } from "../../untils/shortcut";
import K101Card from "./K101/K101Card";
import dayjs from "dayjs";
import { GETDATAK101, GETHNAMEK101, POST } from "../../service";

function K101() {
  const profile = getToken(localStorage.getItem("profile"));
  const year_length = new Date().getFullYear() - 1;
  const yearsData = Array.from({ length: 5 }, (_, index) => {
    return { value: year_length + index };
  });
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(dayjs().year());
  const [tablePregnant, setTablePregnant] = useState([]);
  const [tablePregnantTarget, setTablePregnantTarget] = useState([]);
  const [targetValue, setTargetValue] = useState([]);
  const [hname, setHname] = useState("ทั้งหมด");
  const [dataHname, setDataHname] = useState([]);

  const onSelect = async (name, value) => {
    if (name === "hname") {
      setHname(value);
    }
  };

  const getDataK101 = async () => {
    try {
      setLoading(true);
      let res = await POST(GETDATAK101, { year_quarter: year, hname: hname === "ทั้งหมด" ? "" : hname });
      if (res.success) {
        setLoading(false);
        setTablePregnant(res.result.tablePregnant);
        setTablePregnantTarget(res.result.tablePregnantTarget);
        setTargetValue(res.result.target_value);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "warning",
          text: `ค้นหาไม่สำเร็จ`,
          confirmButtonText: "ตกลง",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        text: `${error}`,
        confirmButtonText: "ตกลง",
      });
    }
  };

  const getHnameK101 = async () => {
    try {
      setLoading(true);
      let res = await POST(GETHNAMEK101, { hname });
      if (res.success) {
        if (profile.role_id === 1 || profile.role_id === 2) {
          res.result.unshift("ทั้งหมด");
        } else {
          setHname(profile.location_name);
        }
        setDataHname(res.result);
        setLoading(false);
      } else {
        setLoading(false);
        Swal.fire({
          icon: "warning",
          text: `ค้นหาไม่สำเร็จ`,
          confirmButtonText: "ตกลง",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        text: `${error}`,
        confirmButtonText: "ตกลง",
      });
    }
  };

  useEffect(() => {
    getDataK101();
  }, [year, hname]);

  useEffect(() => {
    getHnameK101();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ position: "relative" }}>
      <BackdropLoad loading={loading} />
      <K101Card
        profile={profile}
        yearsData={yearsData}
        year={year}
        setYear={setYear}
        tablePregnant={tablePregnant}
        tablePregnantTarget={tablePregnantTarget}
        targetValue={targetValue}
        hname={hname}
        dataHname={dataHname}
        setHname={setHname}
        onSelect={onSelect}
      />
    </Container>
  );
}

export default K101;
