import { Box, Checkbox, Grid, Stack, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
// import MarkerJS from "./Marker.js";
import "leaflet.heat";
import L from "leaflet";

const HeatmapLayer = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    if (points && points.length > 0) {
      const heat = L.heatLayer(points, {
        radius: 20,
        blur: 15,
        maxZoom: 17,
        minOpacity: 0.5,
      }).addTo(map);

      return () => {
        map.removeLayer(heat); // ลบเลเยอร์ออกเมื่อ component ถูกทำลาย
      };
    }
  }, [map, points]);

  return null;
};
const center = window.location.origin.includes("kk")
  ? [16.43192755724492, 102.82856609781523]
  : window.location.origin.includes("mdh")
  ? [16.59738946623057, 104.51104253757008]
  : window.location.origin.includes("spb")
  ? [14.651579065119135, 99.93989999520554]
  : [16.43192755724492, 102.82856609781523];

function MapChart({ data, subdistrictObj }) {
  const [map, setMap] = useState(null);
  const [showGeoJSON, setShowGeoJSON] = useState(true);
  const [showGeoJSON2, setShowGeoJSON2] = useState(true);
  const [showMarker, setShowMarker] = useState(false);

  const timer = useRef();
  const streetViewRef = useRef();

  const heatmapPoints = data
    .map((item) => {
      if (item.position[0] && item.position[1]) {
        // ตรวจสอบว่าพิกัดถูกต้อง
        return [item.position[0], item.position[1], item.intensity || 0.15]; // กำหนด intensity เป็น 1 ถ้าไม่มีค่า
      }
      return null; // ถ้าพิกัดไม่ถูกต้อง ให้คืนค่า null
    })
    .filter((item) => item !== null); // กรองค่าที่เป็น null ออกไป

  const popupFeature = (e) => {
    let layer = e.target;
    const { count, tam_th } = e.target.feature.properties;

    if (count > 0) {
      const popupOptions = {
        minWidth: 100,
        maxWidth: 250,
        className: "popup-classname",
      };

      layer
        .bindTooltip(() => {
          return `<b>${tam_th} : จำนวนผู้ป่วย ${count}</b>`;
        }, popupOptions)
        .openTooltip();
    }

    const originalStyle = {
      weight: layer.options.weight,
      color: layer.options.color,
      dashArray: layer.options.dashArray,
      fillOpacity: layer.options.fillOpacity,
    };

    layer.options.originalStyle = originalStyle;

    layer.setStyle({
      weight: 3,
      color: "#f00",
      dashArray: "",
      fillOpacity: 0.7,
    });
    layer.bringToFront();
  };

  const popupFeatureOut = (e) => {
    let layer = e.target;
    const originalStyle = layer.options.originalStyle;

    if (originalStyle) {
      layer.setStyle(originalStyle);
    }
    layer.bringToBack();
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: popupFeature,
      mouseout: popupFeatureOut,
    });
  };

  const mapPolygonColorToDensity = (d) => {
    if (d > 10000) return "#800026";
    if (d > 5000) return "#BD0026";
    if (d > 2000) return "#E31A1C";
    if (d > 1000) return "#FC4E2A";
    if (d > 500) return "#FD8D3C";
    if (d > 200) return "#FEB24C";
    if (d > 100) return "#FED976";
    if (d >= 1) return "#FFEDA0";
    return "#000";
  };

  const style = (feature) => {
    return {
      fillColor: mapPolygonColorToDensity(feature.properties.count),
      weight: 1,
      opacity: 1,
      color: "white",
      dashArray: "2",
      fillOpacity: 0.5,
      transition: "5s ease-in-out",
      className: "marker marker-fade-in",
    };
  };

  useEffect(() => {
    if (map) {
      map.on("zoomend", () => {
        const currentZoom = map.getZoom();
        if (currentZoom < 12) {
          setShowGeoJSON(currentZoom < 12);
          setShowMarker(currentZoom >= 12);
          timer.current = setTimeout(() => {
            setShowGeoJSON2(true);
          }, 300);
        } else {
          setShowGeoJSON2(currentZoom < 12);
          setTimeout(() => {
            setShowGeoJSON(false);
            setShowMarker(true);
          }, 300);
        }
      });
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [map]);

  useEffect(() => {
    if (window.google) {
      const panorama = new window.google.maps.StreetViewPanorama(streetViewRef.current, {
        position: center,
        pov: { heading: 165, pitch: 0 },
        zoom: 1,
      });
    }
  }, []);

  return (
    <>
      <Grid container columns={12}>
        <Box className="box-info" sx={{ background: "#333333" }} width="100%">
          <Stack spacing={1} className="flex-center" width="100%">
            <Typography style={{ margin: 5 }}>แผนที่แสดงโรดระบาดเป็นรายบ้านแบบดาวเทียม</Typography>
          </Stack>
        </Box>
        <Grid item xs={12} className={showGeoJSON2 ? "n" : ""}>
          <MapContainer scrollWheelZoom={true} center={center} zoom={9} className="mapContainer" ref={setMap}>
            <TileLayer
              // ตรวจสอบสถานะ satelliteView
              maxZoom={18}
              url={
                "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              }
              attribution={
                "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              }
            />

            {showGeoJSON && (
              <GeoJSON
                data={subdistrictObj}
                style={(e) => ({
                  ...style(e),
                })}
                onEachFeature={onEachFeature}
              />
            )}
            {showMarker && <HeatmapLayer points={heatmapPoints} />}
            {/* {showMarker && <MarkerJS filteredMarkers={filteredMarkers} />} */}
          </MapContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default MapChart;
