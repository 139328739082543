import React, { useEffect, useState } from "react";
import { POST, LOGIN } from "../../service";
import Swal from "sweetalert2";
import { Grid, Box, Typography, TextField, Container, IconButton, InputAdornment, InputLabel, FormControl, OutlinedInput } from "@mui/material";
import CookieConsent from "react-cookie-consent";
import { useNavigate } from "react-router-dom";

import { ButtonTeal } from "../../Theme";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import dayjs from "dayjs";

function Login() {
     const navigate = useNavigate();
     const [password, setPassword] = useState("");
     const [email, setEmail] = useState("");
     const [showPassword, setShowPassword] = useState(false);

     const handleClickShowPassword = () => setShowPassword((show) => !show);

     const handleMouseDownPassword = (event) => {
          event.preventDefault();
     };

     const Login = async () => {
          if (password && email) {
               try {
                    let res = await POST(LOGIN, { email, password });
                    if (res.success) {
                         // console.log(res.result);
                         if (res.result.message === "first time login") {
                              navigate("/Approve", { state: { detail: res.result.user_data }, replace: true });
                              // window.location.replace("/Approve");
                              // console.log({res.result.});
                              return;
                         }

                         // window.localStorage.removeItem("screen");
                         window.localStorage.setItem("screen", JSON.stringify(res.result.screen_new));
                         // window.localStorage.removeItem("profile");
                         window.localStorage.setItem("profile", JSON.stringify(res.result.token));
                         // navigate(res.result.screen[0].screen_path);
                         localStorage.setItem("timeOut", dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"));
                         navigate(`/Homepage`);
                    } else {
                         Swal.fire({
                              icon: "warning",
                              text: `${res.message}`,
                              confirmButtonText: "ตกลง",
                         });
                    }
               } catch (error) {
                    alert(error);
               }
          } else {
               Swal.fire({
                    icon: "warning",
                    text: `กรุณากรอกข้อมูลให้ครบถ้วน`,
                    confirmButtonText: "ตกลง",
               });
          }
     };

     const handleKeyPress = (event) => {
          if (event.key === "Enter") {
               Login();
          }
     };

     useEffect(() => {
          const screen = JSON.parse(localStorage.getItem("screen"));
          const profile = JSON.parse(localStorage.getItem("profile"));
          if (screen && profile) {
               // navigate(screen[0].screen_path);
               navigate(`/Homepage`);
          }
     }, [navigate]);

     return (
          <>
               <Container maxWidth="xs" sx={{ position: "relative" }}>
                    <Box className="flex-center" height="100vh">
                         <Grid container columns={12} className="flex-center">
                              <Grid item xs={12} className="flex-center">
                                   <Typography className="gradiant-header">NH Data Network</Typography>
                              </Grid>
                              <Grid item my={2} xs={12} sm={12} md={12}>
                                   <TextField
                                        fullWidth
                                        label="ชื่อผู้ใช้งาน"
                                        name="email"
                                        type="text"
                                        value={email}
                                        variant="outlined"
                                        onKeyDown={handleKeyPress}
                                        onChange={(e) => setEmail(e.currentTarget.value)}
                                   />
                              </Grid>
                              <Grid item my={2} xs={12} sm={12} md={12}>
                                   <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
                                        <OutlinedInput
                                             fullWidth
                                             name="password"
                                             value={password}
                                             variant="outlined"
                                             onKeyDown={handleKeyPress}
                                             onChange={(e) => setPassword(e.currentTarget.value)}
                                             type={showPassword ? "text" : "password"}
                                             endAdornment={
                                                  <InputAdornment position="end">
                                                       <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                       >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                       </IconButton>
                                                  </InputAdornment>
                                             }
                                             label="Password"
                                        />
                                   </FormControl>
                              </Grid>
                              <Grid item my={3} xs={12}>
                                   <ButtonTeal size="large" fullWidth onClick={Login}>
                                        เข้าสู่ระบบ
                                   </ButtonTeal>
                              </Grid>
                         </Grid>
                    </Box>
               </Container>
               <CookieConsent
                    location="top"
                    buttonText="Accept"
                    cookieName="CookieNHDH"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "16px", borderRadius: 5 }}
                    expires={150}
               >
                    This website uses cookies to enhance the user experience.{" "}
               </CookieConsent>
               <footer>
                    <svg viewBox="0 -20 700 110" width="100%" height="110" preserveAspectRatio="none">
                         <path
                              transform="translate(0, -20)"
                              d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700"
                              fill="var(--teal-100)"
                              opacity="0.8"
                         />
                         <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="var(--teal-A700)" />
                    </svg>
               </footer>
          </>
     );
}

export default Login;
