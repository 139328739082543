import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BarChartHos from "./BarChartHos";
import BarChartVertical from "./BarChartVertical";
import ChartCaseOPD from "./ChartCaseOPD";
import ChartOPDClaim from "./ChartOPDClaim";
import DoughnutChart from "./DoughnutChart";
import { Dashboard } from "../../../untils/icons";
import ChartOPDClaimOfHosp from "./ChartOPDClaimofHosp";
import dayjs from "dayjs";
import { Autocomplete, Box, Container, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";

export default function GridCard({
    profile,
    dataLocation,
    searchLoca,
    setSearchLoca,
    setLocation,
    setInputLoca,
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
    setCleared,
    data,
    dataOPD,
    dataPerHosp,
    dataPerMonthNotPass,
    dataPerMonthAll,
    dataSent,
    OPDClaimByHosp,
    dataAmphur,
    amphur,
    setAmphur,
    setAmphurCode,
    auditPass,
    auditNotPass,
}) {
    console.log("auditPass:", auditPass);
    console.log("auditNotPass:", auditNotPass);
    return (
        <Container maxWidth="xl" sx={{ position: "relative" }}>
            <Box display="flex" justifyContent="center">
                <Paper className="paper-bg" elevation={0}>
                    <Grid container columns={12} spacing={3}>
                        <Grid item xs={8} sm={6} md={6} className="flex-start">
                            <Stack direction="row" spacing={2}>
                                <Dashboard fontSize="medium" />
                                <Typography className="main-header">Dashboard OPD</Typography>
                            </Stack>
                        </Grid>

                        {/* Select Data */}
                        <Grid item xs={12}>
                            <Grid container columns={12} spacing={3} className="flex-start">
                                <Grid item xs={12} sm={12} md={3}>
                                    <Autocomplete
                                        disabled={profile.role_id !== 1 && profile.role_id !== 2}
                                        fullWidth
                                        getOptionLabel={(option) => (typeof option === "string" ? option : `${option.ampurname}`)}
                                        options={dataAmphur}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={amphur}
                                        noOptionsText="No Amphur"
                                        onChange={(event, value) => {
                                            setAmphur(value);
                                            if (!value) {
                                                return;
                                            }
                                            if (value) {
                                                setAmphurCode(value.ampurcode);
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} label="อำเภอ" />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                    <Autocomplete
                                        disabled={(profile.role_id !== 1 && profile.role_id !== 2) || !amphur}
                                        fullWidth
                                        getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hcode} ${option.hname}`)}
                                        options={dataLocation}
                                        autoComplete
                                        includeInputInList
                                        filterSelectedOptions
                                        value={searchLoca}
                                        noOptionsText="No locations"
                                        onChange={(event, newValue) => {
                                            // console.log("newValue", newValue);
                                            setSearchLoca(newValue);
                                            if (!newValue) {
                                                return;
                                            }
                                            if (newValue) {
                                                setLocation(newValue.hcode);
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputLoca(newInputValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                            <DatePicker
                                                value={dateStart}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่เริ่มต้น"
                                                onChange={(e) => setDateStart(e)}
                                                views={["year", "month"]}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={2}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                            <DatePicker
                                                // fullWidth
                                                value={dateEnd}
                                                slotProps={{
                                                    field: { clearable: true, onClear: () => setCleared(true) },
                                                }}
                                                label="วันที่สิ้นสุด"
                                                onChange={(e) => setDateEnd(e)}
                                                views={["year", "month"]}
                                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Chart */}
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวนการเข้ารับบริการ</Typography>
                            </Box>
                            <Box className="census-info-frame" minHeight={420}>
                                <DoughnutChart auditPass={auditPass} auditNotPass={auditNotPass} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">รายการที่ Audit ไม่ผ่าน</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <BarChartVertical information={dataOPD} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวนการเข้ารับบริการแต่ละ รพ.สต.</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <BarChartHos information={dataPerHosp} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวน OPD ที่ไม่ผ่าน</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <ChartCaseOPD information={dataPerMonthNotPass} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">กราฟเป้าหมายการเคลม</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <ChartOPDClaim perMonthAll={dataPerMonthAll} dataSent={dataSent} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className="census-info-frame-header">
                                <Typography className="text-info-header">จำนวน OPD ที่ส่งเคลมในระบบ</Typography>
                            </Box>
                            <Box className="census-info-frame">
                                <ChartOPDClaimOfHosp information={OPDClaimByHosp} />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
}
